import React, { useMemo } from "react";
import { UseFormGetValues, FieldErrors } from "react-hook-form";
import { TFormData } from "../../../helpers/types";
import { getYearlyPrice } from "../../../helpers/helpers";
import { EGenericTexts } from "../../../helpers/constants";

interface IPriceStepProps {
  getValues: UseFormGetValues<TFormData>;
  isSubmitting: boolean;
  errors: FieldErrors<TFormData>;
}

const PriceStep: React.FC<IPriceStepProps> = ({
  getValues,
  isSubmitting,
  errors,
}) => {
  const yearlyCost = useMemo(() => getYearlyPrice(getValues()), [getValues]);

  return (
    <>
      <h2>
        {EGenericTexts.cost}
        {yearlyCost}
      </h2>
      {errors?.domainName && (
        <p className="error-text">{`${errors?.domainName?.message}`}</p>
      )}
      {errors?.selectedPlan && (
        <p className="error-text">{`${errors?.selectedPlan?.message}`}</p>
      )}
      <button className="button" disabled={isSubmitting} type="submit">
        {EGenericTexts.submit}
      </button>
    </>
  );
};

export default PriceStep;
