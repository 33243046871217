import { TFormData, TExistingPlan, TStorageFormData } from "./types";
import {
  ServerPlans,
  domainYearlyPrice,
  gbMonthlyprice,
  emailSecurityMonthlyPrice,
} from "./constants";

export const isDomainName = (value: string) => {
  const domainRegex =
    /^(?!:\/\/)(?:(?![\s])[\w-]{1,63}(?<!-)\.?)+(?![\s])\.[\w-]{2,24}$/;
  return domainRegex.test(value);
};

export const getYearlyPrice = (data: TFormData): number => {
  let totalPrice = 0;

  if (data.isDomainActive) {
    totalPrice += domainYearlyPrice;
  }

  if (data.isEmailActive) {
    totalPrice += emailSecurityMonthlyPrice * 12;
  }

  if (data.isPlanActive && data.selectedPlan) {
    const selectedPlan = ServerPlans.find(
      (plan) => plan.planName === data.selectedPlan
    );
    if (selectedPlan) {
      totalPrice += selectedPlan.monthlyCost * 12;
    }
  }

  return Math.round(totalPrice * 100) / 100;
};

export const getUpdatedYearlyPrice = (
  existingPlan: TExistingPlan,
  data: TStorageFormData
): number => {
  const additionalCost = data.availableStorage * gbMonthlyprice * 12;
  const totalPrice = existingPlan?.serviceYearlyCost + additionalCost;

  return Math.round(totalPrice * 100) / 100;
};

export const cleanupValues = (data: TFormData) => {
  if (!data.isDomainActive) {
    data.domainName = "";
  }
  if (!data.isPlanActive) {
    data.selectedPlan = "";
  }

  return data;
};
