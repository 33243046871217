import React, { ReactNode } from "react";
import Modal from "react-modal";
import "./ModalWrapper.css";
import { EGenericTexts } from "../../helpers/constants";

interface IModalWrapperProps {
  isModalOpen: boolean;
  closeModal: () => void;
  children: ReactNode;
}

const ModalWrapper: React.FC<IModalWrapperProps> = ({
  isModalOpen,
  closeModal,
  children,
}) => {
  return (
    <Modal
      isOpen={isModalOpen}
      className="react-modal"
      onRequestClose={closeModal}
      ariaHideApp={false}
    >
      {children}
      <button className="button" onClick={closeModal}>
        {EGenericTexts.close}
      </button>
    </Modal>
  );
};

export default ModalWrapper;
