import React from "react";
import { FieldErrors, UseFormRegister } from "react-hook-form";
import { TFormData } from "../../../helpers/types";
import { ENewServiceTexts, EGenericTexts } from "../../../helpers/constants";

interface IDomainNameStepProps {
  register: UseFormRegister<TFormData>;
  errors: FieldErrors<TFormData>;
}

const DomainNameStep: React.FC<IDomainNameStepProps> = ({
  register,
  errors,
}) => {
  return (
    <>
      <h2>{ENewServiceTexts.enterDomain}</h2>
      <input
        className="input-field"
        type="text"
        {...register("domainName", { required: true })}
        placeholder={EGenericTexts.placeholderDomain}
      />
      {errors?.domainName && (
        <p className="error-text">{`${errors?.domainName?.message}`}</p>
      )}
    </>
  );
};

export default DomainNameStep;
