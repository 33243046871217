import React, { ReactNode } from "react";
import { EGenericTexts } from "../../helpers/constants";

interface IStepWrapperProps {
  children: ReactNode;
  handleNextStep: () => void;
  handleValidateNextStep?: () => Promise<void>;
  handlePrevStep: () => void;
  isNextStepVisible?: boolean;
  isPrevStepVisible?: boolean;
  isValidateNextStep?: boolean;
}

const StepWrapper: React.FC<IStepWrapperProps> = ({
  children,
  handleNextStep,
  handlePrevStep,
  handleValidateNextStep,
  isNextStepVisible = false,
  isPrevStepVisible = true,
  isValidateNextStep = false,
}) => {
  return (
    <>
      {children}
      {isPrevStepVisible && (
        <button className="button" type="button" onClick={handlePrevStep}>
          {EGenericTexts.previous}
        </button>
      )}
      {isNextStepVisible && !isValidateNextStep && (
        <button className="button" type="button" onClick={handleNextStep}>
          {EGenericTexts.next}
        </button>
      )}
      {isNextStepVisible && isValidateNextStep && (
        <button
          className="button"
          type="button"
          onClick={handleValidateNextStep}
        >
          {EGenericTexts.next}
        </button>
      )}
    </>
  );
};

export default StepWrapper;
