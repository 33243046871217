import React from "react";
import { FieldErrors, UseFormRegister } from "react-hook-form";
import { TStorageFormData } from "../../../helpers/types";
import { EResourcesTexts, EGenericTexts } from "../../../helpers/constants";

interface IEnterStorageStepProps {
  register: UseFormRegister<TStorageFormData>;
  errors: FieldErrors<TStorageFormData>;
}

const EnterStorageStep: React.FC<IEnterStorageStepProps> = ({
  register,
  errors,
}) => {
  return (
    <>
      <h2>{EResourcesTexts.enterStorage}</h2>
      <input
        className="input-field"
        type="number"
        {...register("availableStorage", {
          valueAsNumber: true,
        })}
        placeholder={EGenericTexts.placeholderStorage}
      />
      {errors?.availableStorage && (
        <p className="error-text">{`${errors?.availableStorage?.message}`}</p>
      )}
    </>
  );
};

export default EnterStorageStep;
