import React, { useMemo } from "react";
import { UseFormGetValues } from "react-hook-form";
import { TStorageFormData, TExistingPlan } from "../../../helpers/types";
import { getUpdatedYearlyPrice } from "../../../helpers/helpers";
import { EGenericTexts } from "../../../helpers/constants";

interface IStoragePriceStepProps {
  getValues: UseFormGetValues<TStorageFormData>;
  existingPlans: TExistingPlan[];
  isSubmitting: boolean;
}

const StoragePriceStep: React.FC<IStoragePriceStepProps> = ({
  getValues,
  existingPlans,
  isSubmitting,
}) => {
  const chosenPlan = existingPlans.find(
    (el) => el["service"] === getValues().service
  );
  const yearlyCost = useMemo(
    () => getUpdatedYearlyPrice(chosenPlan as TExistingPlan, getValues()),
    [chosenPlan, getValues]
  );

  return (
    <>
      <h2>
        {EGenericTexts.updatedCost}
        {yearlyCost}
      </h2>
      <button className="button" disabled={isSubmitting} type="submit">
        {EGenericTexts.submit}
      </button>
    </>
  );
};

export default StoragePriceStep;
