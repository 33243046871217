import { z } from "zod";
import { EValidationErrors } from "./constants";
import { isDomainName } from "./helpers";
import { TExistingPlan } from "./types";

export const createNewServiceSchema = (plans: TExistingPlan[]) => {
  return z
    .object({
      isDomainActive: z.boolean(),
      domainName: z.string(),
      isPlanActive: z.boolean(),
      selectedPlan: z.string(),
      isEmailActive: z.boolean(),
    })
    .refine(
      (data) => {
        if (data.isDomainActive === true && !data.domainName) {
          return false;
        }
        return true;
      },
      {
        message: EValidationErrors.required,
        path: ["domainName"],
      }
    )
    .refine(
      (data) => {
        if (data.isDomainActive === true) {
          if (plans.some((plan) => plan.domainName === data.domainName)) {
            return false;
          }
        }
        return true;
      },
      {
        message: EValidationErrors.domainExists,
        path: ["domainName"],
      }
    )
    .refine(
      (data) => {
        if (data.isDomainActive === true) {
          return isDomainName(data.domainName);
        }
        return true;
      },
      {
        message: EValidationErrors.domainFormat,
        path: ["domainName"],
      }
    )
    .refine(
      (data) => {
        if (data.isPlanActive === true && !data.selectedPlan) {
          return false;
        }
        return true;
      },
      {
        message: EValidationErrors.required,
        path: ["selectedPlan"],
      }
    );
};

export const addStorageSchema = z.object({
  service: z
    .string({
      required_error: EValidationErrors.required,
      invalid_type_error: EValidationErrors.numberRequired,
    })
    .min(1, {
      message: EValidationErrors.required,
    }),
  availableStorage: z
    .number({
      required_error: EValidationErrors.required,
      invalid_type_error: EValidationErrors.numberRequired,
    })
    .min(0)
    .max(99999),
});
