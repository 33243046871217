import React from "react";
import { FieldErrors, UseFormRegister } from "react-hook-form";
import { ServerPlans, ENewServiceTexts } from "../../../helpers/constants";
import { TFormData } from "../../../helpers/types";

interface IChoosePlanStepProps {
  register: UseFormRegister<TFormData>;
  errors: FieldErrors<TFormData>;
}

const ChoosePlanStep: React.FC<IChoosePlanStepProps> = ({
  register,
  errors,
}) => {
  return (
    <>
      <h2>{ENewServiceTexts.choosePlan}</h2>
      <select
        id="selectedPlan"
        {...register("selectedPlan")}
        className="select-field"
        defaultValue=""
      >
        <option value="" disabled>
          {ENewServiceTexts.choosePlan}
        </option>
        {ServerPlans?.map((el) => (
          <option key={el?.planId} value={el?.planName}>
            {el?.planName}
          </option>
        ))}
      </select>
      {errors?.selectedPlan && (
        <p className="error-text">{`${errors?.selectedPlan?.message}`}</p>
      )}
    </>
  );
};

export default ChoosePlanStep;
