import React from "react";
import { UseFormSetValue } from "react-hook-form";
import { TFormData, TQuestionStepField } from "../../../helpers/types";
import { EGenericTexts } from "../../../helpers/constants";

interface IQuestionStepProps {
  setValue: UseFormSetValue<TFormData>;
  question: string;
  valueName: TQuestionStepField;
  handleNextStep: () => void;
  handleSkipStep: () => void;
}

const QuestionStep: React.FC<IQuestionStepProps> = ({
  setValue,
  question,
  valueName,
  handleNextStep,
  handleSkipStep,
}) => {
  const handleButtonClick = (value: boolean) => {
    setValue(valueName, value);
    if (value) {
      handleNextStep();
    } else {
      handleSkipStep();
    }
  };

  return (
    <>
      <h2>{question}</h2>
      <button
        className="button"
        type="button"
        onClick={() => handleButtonClick(true)}
      >
        {EGenericTexts.yes}
      </button>
      <button
        className="button"
        type="button"
        onClick={() => handleButtonClick(false)}
      >
        {EGenericTexts.no}
      </button>
    </>
  );
};

export default QuestionStep;
