import React, { useState, Dispatch, SetStateAction } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import StepWrapper from "../StepWrapper/StepWrapper";
import QuestionStep from "./QuestionStep/QuestionStep";
import DomainNameStep from "./DomainNameStep/DomainNameStep";
import ChoosePlanStep from "./ChoosePlanStep/ChoosePlanStep";
import PriceStep from "./PriceStep/PriceStep";
import { getYearlyPrice, cleanupValues } from "../../helpers/helpers";
import { TFormData, TExistingPlan, TValidateField } from "../../helpers/types";
import { createNewServiceSchema } from "../../helpers/zodSchemas";
import {
  VALIDATE_DOMAIN_NAME,
  VALIDATE_SELECTED_PLAN,
  defaultNewService,
  ENewServiceTexts,
} from "../../helpers/constants";

interface INewServiceWizardProps {
  closeModal: () => void;
  existingPlans: TExistingPlan[];
  setExistingPlans: Dispatch<SetStateAction<TExistingPlan[]>>;
}

const NewServiceWizard: React.FC<INewServiceWizardProps> = ({
  closeModal,
  existingPlans,
  setExistingPlans,
}) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [previousSteps, setPreviousSteps] = useState<number[]>([]);
  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    trigger,
    reset,
    getFieldState,
    formState: { errors, isSubmitting },
  } = useForm<TFormData>({
    mode: "onChange",
    defaultValues: defaultNewService,
    resolver: zodResolver(createNewServiceSchema(existingPlans)),
  });

  const handleNextStep = () => {
    setPreviousSteps([...previousSteps, currentStep]);
    setCurrentStep(currentStep + 1);
  };

  const handleSkipStep = () => {
    setPreviousSteps([...previousSteps, currentStep]);
    setCurrentStep(currentStep + 2);
  };

  const handlePrevStep = () => {
    const prevStep = previousSteps.pop();
    setPreviousSteps([...previousSteps]);
    setCurrentStep(prevStep || 1);
  };

  const handleValidateNextStep = async (validateField: TValidateField) => {
    await trigger(validateField);

    if (!getFieldState(validateField).error) {
      handleNextStep();
    }
  };

  const onSubmit = async (data: TFormData) => {
    const yearlyCost = getYearlyPrice(data);
    const cleanedUpData = cleanupValues(data);
    setExistingPlans((plans) => [
      ...plans,
      {
        ...cleanedUpData,
        serviceYearlyCost: yearlyCost,
        yearlyCost: yearlyCost,
        availableStorage: 0,
        service: "Service " + (existingPlans.length + 1),
      },
    ]);
    // setExistingPlans((plans) => [
    //   ...plans,
    //   { ...data, yearlyCost, availableStorage: 0 },
    // ]);

    reset();
    closeModal();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {currentStep === 1 && (
        <StepWrapper
          handleNextStep={handleNextStep}
          handlePrevStep={handlePrevStep}
          isPrevStepVisible={false}
        >
          <QuestionStep
            setValue={setValue}
            question={ENewServiceTexts.domainRegQ}
            valueName="isDomainActive"
            handleNextStep={handleNextStep}
            handleSkipStep={handleSkipStep}
          />
        </StepWrapper>
      )}
      {currentStep === 2 && (
        <StepWrapper
          handleNextStep={handleNextStep}
          handleValidateNextStep={() =>
            handleValidateNextStep(VALIDATE_DOMAIN_NAME)
          }
          handlePrevStep={handlePrevStep}
          isNextStepVisible
          isValidateNextStep
        >
          <DomainNameStep register={register} errors={errors} />
        </StepWrapper>
      )}
      {currentStep === 3 && (
        <StepWrapper
          handleNextStep={handleNextStep}
          handlePrevStep={handlePrevStep}
        >
          <QuestionStep
            setValue={setValue}
            question={ENewServiceTexts.planQ}
            valueName="isPlanActive"
            handleNextStep={handleNextStep}
            handleSkipStep={handleSkipStep}
          />
        </StepWrapper>
      )}
      {currentStep === 4 && (
        <StepWrapper
          handleNextStep={handleNextStep}
          handleValidateNextStep={() =>
            handleValidateNextStep(VALIDATE_SELECTED_PLAN)
          }
          handlePrevStep={handlePrevStep}
          isNextStepVisible
          isValidateNextStep
        >
          <ChoosePlanStep register={register} errors={errors} />
        </StepWrapper>
      )}
      {currentStep === 5 && (
        <StepWrapper
          handleNextStep={handleNextStep}
          handlePrevStep={handlePrevStep}
        >
          <QuestionStep
            setValue={setValue}
            question={ENewServiceTexts.emailQ}
            valueName="isEmailActive"
            handleNextStep={handleNextStep}
            handleSkipStep={handleNextStep}
          />
        </StepWrapper>
      )}
      {currentStep === 6 && (
        <StepWrapper
          handleNextStep={handleNextStep}
          handlePrevStep={handlePrevStep}
        >
          <PriceStep
            getValues={getValues}
            isSubmitting={isSubmitting}
            errors={errors}
          />
        </StepWrapper>
      )}
    </form>
  );
};

export default NewServiceWizard;
