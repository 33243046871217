import React from "react";
import { FieldErrors, UseFormRegister } from "react-hook-form";
import { TStorageFormData, TExistingPlan } from "../../../helpers/types";
import { EResourcesTexts } from "../../../helpers/constants";

interface IChooseDomainStepProps {
  register: UseFormRegister<TStorageFormData>;
  errors: FieldErrors<TStorageFormData>;
  existingPlans: TExistingPlan[];
}

const ChooseDomainStep: React.FC<IChooseDomainStepProps> = ({
  register,
  errors,
  existingPlans,
}) => {
  return (
    <>
      <h2>{EResourcesTexts.selectService}</h2>

      <select
        id="service"
        {...register("service", { required: true })}
        className="select-field"
        defaultValue=""
      >
        <option value="" disabled>
          {EResourcesTexts.selectService}
        </option>
        {existingPlans?.map((el) => (
          <option key={el?.service} value={el?.service}>
            {el?.service}
          </option>
        ))}
      </select>
      {errors?.service && (
        <p className="error-text">{`${errors?.service?.message}`}</p>
      )}
    </>
  );
};

export default ChooseDomainStep;
