import React, { useState, Dispatch, SetStateAction } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import StepWrapper from "../StepWrapper/StepWrapper";
import ChooseDomainStep from "./ChooseServiceStep/ChooseServiceStep";
import EnterStorageStep from "./EnterStorageStep/EnterStorageStep";
import StoragePriceStep from "./StoragePriceStep/StoragePriceStep";
import {
  TExistingPlan,
  TStorageFormData,
  TValidateFieldStorage,
} from "../../helpers/types";
import { addStorageSchema } from "../../helpers/zodSchemas";
import { getUpdatedYearlyPrice } from "../../helpers/helpers";
import {
  defaultAddStorage,
  VALIDATE_SERVICE,
  VALIDATE_AVAILABLE_STORAGE,
} from "../../helpers/constants";

interface IAddStorageWizardProps {
  closeModal: () => void;
  existingPlans: TExistingPlan[];
  setExistingPlans: Dispatch<SetStateAction<TExistingPlan[]>>;
}

const AddStorageWizard: React.FC<IAddStorageWizardProps> = ({
  closeModal,
  existingPlans,
  setExistingPlans,
}) => {
  const [currentStep, setCurrentStep] = useState(1);
  const {
    register,
    getValues,
    handleSubmit,
    trigger,
    reset,
    getFieldState,
    formState: { errors, isSubmitting },
  } = useForm<TStorageFormData>({
    mode: "onChange",
    defaultValues: defaultAddStorage,
    resolver: zodResolver(addStorageSchema),
  });

  const handleNextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const handlePrevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleValidateNextStep = async (
    validateField: TValidateFieldStorage
  ) => {
    await trigger(validateField);

    if (!getFieldState(validateField).error) {
      handleNextStep();
    }
  };

  const onSubmit = async (data: TStorageFormData) => {
    const chosenPlan = existingPlans.find(
      (el) => el["service"] === data.service
    );
    const yearlyCost = getUpdatedYearlyPrice(chosenPlan as TExistingPlan, data);

    const newPlanData = existingPlans.map((plan) => {
      if (plan.service === data.service) {
        return {
          ...plan,
          yearlyCost: yearlyCost,
          availableStorage: data.availableStorage,
        };
      }
      return plan;
    });
    setExistingPlans(newPlanData);

    reset();
    closeModal();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {currentStep === 1 && (
        <StepWrapper
          handleNextStep={handleNextStep}
          handleValidateNextStep={() =>
            handleValidateNextStep(VALIDATE_SERVICE)
          }
          handlePrevStep={handlePrevStep}
          isNextStepVisible
          isValidateNextStep
        >
          <ChooseDomainStep
            register={register}
            errors={errors}
            existingPlans={existingPlans}
          />
        </StepWrapper>
      )}
      {currentStep === 2 && (
        <StepWrapper
          handleNextStep={handleNextStep}
          handleValidateNextStep={() =>
            handleValidateNextStep(VALIDATE_AVAILABLE_STORAGE)
          }
          handlePrevStep={handlePrevStep}
          isNextStepVisible
          isValidateNextStep
        >
          <EnterStorageStep register={register} errors={errors} />
        </StepWrapper>
      )}
      {currentStep === 3 && (
        <StepWrapper
          handleNextStep={handleNextStep}
          handlePrevStep={handlePrevStep}
        >
          <StoragePriceStep
            getValues={getValues}
            isSubmitting={isSubmitting}
            existingPlans={existingPlans}
          />
        </StepWrapper>
      )}
    </form>
  );
};

export default AddStorageWizard;
