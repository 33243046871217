import {
  TServerPlan,
  TValidateField,
  TFormData,
  TExistingPlan,
  TQuestionStepField,
  TValidateFieldStorage,
  TStorageFormData,
} from "./types";

export enum ECardTexts {
  domainActive = "Domain Active: ",
  domain = "Domain: ",
  planActive = "Plan Active: ",
  plan = "Plan: ",
  emailActive = "Email security active: ",
  cost = "Yearly Cost €: ",
  storage = "Available storage: ",
  service = "Service name: ",
  serviceCost = "Yearly cost before storage €: ",
}

export enum EValidationErrors {
  required = "This field is required",
  domainFormat = "Provide correct domain format",
  numberRequired = "This field must be a number",
  minimumAmount = "Amount should be higher than 0",
  domainExists = "This domain is already used",
  deleteDomain = "Please delete domain, if you haven't selected domain option",
}

export enum EGenericTexts {
  yes = "Yes",
  no = "No",
  next = "Next",
  previous = "Previous",
  submit = "Submit",
  close = "Close modal",
  cost = "Total cost: €",
  updatedCost = "Updated Total cost: €",
  addNew = "Add new service",
  manageResources = "Manage resources",
  none = "None",
  placeholderDomain = "example.com",
  placeholderStorage = "amount in GB",
}

export enum ENewServiceTexts {
  domainRegQ = "Do you want to register a domain?",
  enterDomain = "Enter domain name:",
  planQ = "Do you need a plan?",
  choosePlan = "Choose a Plan",
  emailQ = "Do you need email protection service?",
}

export enum EResourcesTexts {
  selectService = "Select service",
  enterStorage = "Enter your desired storage:",
}

export const ServerPlans: TServerPlan[] = [
  { planName: "Planas 1", planId: "001", monthlyCost: 8.9 },
  { planName: "Planas 2", planId: "002", monthlyCost: 12.3 },
  { planName: "Planas 3", planId: "003", monthlyCost: 29.9 },
];

export const domainYearlyPrice = 8;
export const emailSecurityMonthlyPrice = 8;
export const gbMonthlyprice = 0.12;

export const VALIDATE_DOMAIN_NAME: TValidateField = "domainName";
export const VALIDATE_SELECTED_PLAN: TValidateField = "selectedPlan";
export const VALIDATE_SERVICE: TValidateFieldStorage = "service";
export const VALIDATE_AVAILABLE_STORAGE: TValidateFieldStorage =
  "availableStorage";

export const IS_DOMAIN: TQuestionStepField = "isDomainActive";
export const IS_PLAN: TQuestionStepField = "isPlanActive";
export const IS_EMAIL_SECURITY: TQuestionStepField = "isEmailActive";

export const defaultNewService: TFormData = {
  isDomainActive: false,
  domainName: "",
  isPlanActive: false,
  selectedPlan: "",
  isEmailActive: false,
};

export const defaultAddStorage: TStorageFormData = {
  service: "",
  availableStorage: 0,
};

export const EXAMPLE_PLANS: TExistingPlan[] = [
  {
    service: "Service 1",
    isDomainActive: true,
    domainName: "example.com",
    isPlanActive: false,
    selectedPlan: "",
    isEmailActive: false,
    serviceYearlyCost: 8,
    yearlyCost: 8,
    availableStorage: 0,
  },
];
