import React, { useState } from "react";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import NewServiceWizard from "../NewServiceWizard/NewServiceWizard";
import AddStorageWizard from "../AddStorageWizard/AddStorageWizard";
import SimpleInfoCard from "../SimpleInfoCard/SimpleInfoCard";
import { TExistingPlan } from "../../helpers/types";
import { EXAMPLE_PLANS, EGenericTexts } from "../../helpers/constants";

const HostingServices: React.FC = () => {
  const [existingPlans, setExistingPlans] =
    useState<TExistingPlan[]>(EXAMPLE_PLANS);
  const [isNewServiceOpen, setIsNewServiceOpen] = useState(false);
  const [isResourcesOpen, setIsResourcesOpen] = useState(false);

  const openNewServicesModal = () => setIsNewServiceOpen(true);
  const closeNewServicesModal = () => setIsNewServiceOpen(false);

  const openResourcesModal = () => setIsResourcesOpen(true);
  const closeResourcesModal = () => setIsResourcesOpen(false);

  return (
    <div>
      {existingPlans.map((plan) => (
        <SimpleInfoCard key={plan.service} plan={plan} />
      ))}
      <button className="button" onClick={openNewServicesModal}>
        {EGenericTexts.addNew}
      </button>
      <button className="button" onClick={openResourcesModal}>
        {EGenericTexts.manageResources}
      </button>
      <ModalWrapper
        isModalOpen={isNewServiceOpen}
        closeModal={closeNewServicesModal}
      >
        <NewServiceWizard
          closeModal={closeNewServicesModal}
          existingPlans={existingPlans}
          setExistingPlans={setExistingPlans}
        />
      </ModalWrapper>
      <ModalWrapper
        isModalOpen={isResourcesOpen}
        closeModal={closeResourcesModal}
      >
        <AddStorageWizard
          closeModal={closeResourcesModal}
          existingPlans={existingPlans}
          setExistingPlans={setExistingPlans}
        />
      </ModalWrapper>
    </div>
  );
};

export default HostingServices;
