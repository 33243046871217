import React from "react";
import { TExistingPlan } from "../../helpers/types";
import { ECardTexts, EGenericTexts } from "../../helpers/constants";

interface ISimpleInfoCardProps {
  plan: TExistingPlan;
}

const SimpleInfoCard: React.FC<ISimpleInfoCardProps> = ({ plan }) => {
  return (
    <div className="card">
      <div className="card-column">
        <div className="card-row">
          <p className="card-label">{ECardTexts.service}</p>
          <p className="card-info" style={{ fontWeight: 700 }}>
            {plan?.service}
          </p>
        </div>
        <div className="card-row">
          <p className="card-label">{ECardTexts.emailActive}</p>
          <p className="card-info">
            {plan?.isEmailActive ? EGenericTexts.yes : EGenericTexts.no}
          </p>
        </div>
        <div className="card-row">
          <p className="card-label">{ECardTexts.serviceCost}</p>
          <p className="card-info">{plan?.serviceYearlyCost}</p>
        </div>
        <div className="card-row">
          <p className="card-label">{ECardTexts.storage}</p>
          <p className="card-info">{plan?.availableStorage}</p>
        </div>
        <div className="card-row">
          <p className="card-label">{ECardTexts.cost}</p>
          <p className="card-info">{plan?.yearlyCost}</p>
        </div>
      </div>
      <div className="card-column">
        <div className="card-row">
          <p className="card-label">{ECardTexts.domainActive}</p>
          <p className="card-info">
            {plan?.isDomainActive ? EGenericTexts.yes : EGenericTexts.no}
          </p>
        </div>
        <div className="card-row">
          <p className="card-label">{ECardTexts.domain}</p>
          <p className="card-info">{plan?.domainName}</p>
        </div>
        <div className="card-row">
          <p className="card-label">{ECardTexts.planActive}</p>
          <p className="card-info">
            {plan?.isPlanActive ? EGenericTexts.yes : EGenericTexts.no}
          </p>
        </div>
        <div className="card-row">
          <p className="card-label">{ECardTexts.plan}</p>
          <p className="card-info">
            {plan?.selectedPlan || EGenericTexts.none}
          </p>
        </div>
      </div>
    </div>
  );
};

export default SimpleInfoCard;
